import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import getCategoryPath from 'CasinoV2/helpers/get_category_path';
import { useIntersection } from 'bv-hooks';
import { Link, Skeleton } from 'bv-components';

const Widget = ({ url, categorySlug }) => {
  const title = categorySlug.charAt(0).toUpperCase() + categorySlug.slice(1);
  const ref = useRef();
  const [loading, setLoading] = useState(true);
  const inViewport = useIntersection(ref, '15%');

  return (
    <div className="casino-game-widget" ref={ref}>
      {categorySlug && (
        <Link
          to={getCategoryPath({ url: categorySlug })}
          data-react="true"
          aria-label={title}
          role="img"
        />
      )}
      {loading && <Skeleton hasHeader={false} />}
      {inViewport && <iframe src={url} onLoad={() => setLoading(false)} />}
    </div>
  );
};

Widget.propTypes = {
  url: PropTypes.string.isRequired,
  categorySlug: PropTypes.string.isRequired,
};

export default Widget;
