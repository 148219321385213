import { t } from 'bv-i18n';

const EmptyFavorites = () => (
  <div className="favourite-games">
    <div className="favourite-games__icon" />

    <h3 className="favourite-games__title bvs-h5">{t('favorite_games.no_games.header')}</h3>

    <p>{t('favorite_games.no_games.message_1')}</p>
    <p>{t('favorite_games.no_games.message_2')}</p>
  </div>
);

export default EmptyFavorites;
