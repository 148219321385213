import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Carousel } from 'bv-components';

import {
  selectCatalogCategories, selectShowNavigation,
} from 'CasinoV2/selectors/casino_selector';
import getCategoryPath from 'CasinoV2/helpers/get_category_path';
import { getSlug } from 'CasinoV2/helpers/config';
import ScrollingContext from '../scrolling_context';

import NavItem from './nav_item';

export const Nav = ({ categories, showNavigation }) => {
  const { pathname } = useLocation();
  const { fixedNav } = useContext(ScrollingContext);

  if (!showNavigation) return null;

  const wrapperClass = classNames('casino-categories-navigation', {
    'is-scrolling': fixedNav,
  });

  if (!categories.length) return null;

  return (
    <div className={wrapperClass}>
      <Carousel
        scrollStep={234}
        embedded
      >
        {
          categories
            .map((category, index) => {
              const path = getCategoryPath(category);
              // TODO: Use routing selector (makeIsCurrentPath)
              const isActive = pathname === path
                || (index === 0 && ['/', `/${getSlug()}`].includes(pathname));

              return (
                <NavItem
                  key={category.url}
                  category={category}
                  path={path}
                  selected={isActive}
                />
              );
            })
        }
      </Carousel>
    </div>
  );
};

Nav.defaultProps = {
  showNavigation: true,
};

Nav.propTypes = {
  categories: PropTypes.instanceOf(Array).isRequired,
  showNavigation: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  categories: selectCatalogCategories(state),
  showNavigation: selectShowNavigation(state),
});

export default connect(mapStateToProps)(Nav);
