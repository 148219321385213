import { setMetaInfo } from 'bv-services/seo';

export default (category) => {
  setMetaInfo(() => {
    if (!category) return null;

    const { url, name, is_live_casino: isLiveCasino } = category;

    return ({
      id: url,
      type: isLiveCasino ? 'live_casino_category' : 'casino_category',
      data: {
        category: name,
        isLiveCasino,
      },
    });
  });
};
