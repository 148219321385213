export const getFilterTagsFromUrl = (searchParamValue) => {
  const params = new URLSearchParams(searchParamValue);
  const filterTags = params?.get('filterTags');

  return !filterTags ? [] : filterTags.split(',').map((numString) => +numString);
};

export const getFilterTagsToParse = (fGroups, fTagsArr) => {
  const fTagsToParseArr = [];

  fGroups.map(
    (fGroup) => fGroup.filterTags.map(
      (fTag) => fTagsArr.map((fId) => fTag.id === fId && fTagsToParseArr.push(fTag)),
    ));

  return fTagsToParseArr;
};
