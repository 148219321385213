import { GamesSlider, GamesList } from 'casino-components';
import PropTypes from 'prop-types';

const ORIENTATION = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
};

const BingoGamesListComponent = ({ label, direction, ...gamesListProps }) => (
  <>
    <h2 className="bvs-header">{label}</h2>
    { direction === ORIENTATION.HORIZONTAL
      ? <GamesSlider className="bingo-games" {...gamesListProps} bingoGame />
      : <GamesList className="bingo-games__all" {...gamesListProps} bingoGame />}
  </>
);

BingoGamesListComponent.propTypes = {
  direction: PropTypes.oneOf(Object.values(ORIENTATION)),
  label: PropTypes.string.isRequired,
};

BingoGamesListComponent.defaultProps = {
  direction: ORIENTATION.VERTICAL,
};

export default BingoGamesListComponent;
