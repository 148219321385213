import { connect } from 'react-redux';
import { compose } from 'underscore';
import { fetchCatalog } from 'CasinoV2/ducks/casino_thunks';
import { resetCasino } from 'CasinoV2/ducks/casino_store';

import AppContainer from './app_container';

const mapDispatchToProps = (dispatch) => ({
  fetchCatalog: compose(dispatch, fetchCatalog),
  resetCasino: compose(dispatch, resetCasino),
});

export default connect(null, mapDispatchToProps)(AppContainer);
