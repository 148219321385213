import { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'bv-components';

const NavItem = memo(({ path, selected, category }) => (
  <Link to={path}>
    <span className={classnames('bvs-button-chip is-small', { active: selected })}>
      {category.name}
    </span>
  </Link>
));

NavItem.propTypes = {
  category: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
};

export default NavItem;
