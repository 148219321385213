import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GamesList } from 'casino-components';
import { selectFavorites, selectHasFetched } from 'CasinoGlobal/redux/favorites/selectors';
import EmptyFavorites from './empty_favorites';

const FavoriteGamesComponent = ({
  id, favorites, gameInstances, fetching, favoritesHasFetched, ...gamesListProps
}) => {
  const [filteredGames, setFilteredGames] = useState(gameInstances);

  const filterGames = () => {
    const gameInstancesMap = gameInstances.reduce((acc, gameInstance) => {
      acc[gameInstance.gameId] = gameInstance;
      return acc;
    }, {});

    const filteredGameInstances = favorites.map(
      (gameId) => gameInstancesMap[gameId]).filter(Boolean);

    setFilteredGames(filteredGameInstances);
  };

  useEffect(() => {
    if (favoritesHasFetched) {
      setTimeout(() => {
        filterGames();
      }, 400);
    }
  }, [favorites, gameInstances]);

  if (!filteredGames?.length) return (<EmptyFavorites />);

  return (
    <GamesList
      id={id}
      gameInstances={filteredGames}
      favoritesHasFetched={favoritesHasFetched}
      {...gamesListProps}
    />
  );
};

FavoriteGamesComponent.propTypes = {
  type: PropTypes.string.isRequired,
  gameInstances: PropTypes.instanceOf(Array).isRequired,
  favorites: PropTypes.instanceOf(Array).isRequired,
  favoritesHasFetched: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  fetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  favorites: selectFavorites(state),
  favoritesHasFetched: selectHasFetched(state),
});

export default connect(mapStateToProps)(FavoriteGamesComponent);
