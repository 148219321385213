import { t } from 'bv-i18n';
import { useSelector } from 'react-redux';
import { selectHasFavorites } from 'CasinoGlobal/redux/favorites/selectors';
import { getSlug } from 'CasinoV2/helpers/config';
import { Link } from 'bv-components';
import classNames from 'classnames';

const FavoritesButton = () => {
  const hasFavorites = useSelector((state) => selectHasFavorites(state));

  const favoritesClass = classNames('casino-search-nav__option-icon favourite-games__icon', {
    'has-notification': hasFavorites,
  });

  return (
    <Link className="casino-search-nav__option" to={`/${getSlug()}/favorites`} aria-label="Favourites">
      <span className={favoritesClass} />
      {t('favourites')}
    </Link>
  );
};

export default FavoritesButton;
