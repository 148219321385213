import PropTypes from 'prop-types';
import classNames from 'classnames';
import FilterTags from './filter_tags';

const FilterGroup = ({
  id,
  name,
  label,
  isExpandable,
  isSuppliers,
  filterTags,
}) => filterTags?.length && (
<div
  className={classNames('casino-filter__group', {
    'is-suppliers': isSuppliers,
  })}
>
  <h2 className="bvs-header">{label || name}</h2>
  <FilterTags
    filterTags={filterTags}
    isExpandable={isExpandable}
    isSuppliers={isSuppliers}
    key={id}
  />
</div>
);

FilterGroup.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isExpandable: PropTypes.bool.isRequired,
  isSuppliers: PropTypes.bool.isRequired,
  filterTags: PropTypes.instanceOf(Array).isRequired,
};

export default FilterGroup;
