import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { t } from 'bv-i18n';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilterTagsInUrl, selectSelectedFilterTags } from 'CasinoGlobal/redux/all_categories_filter/selectors';
import { getSlug, getTag } from 'CasinoV2/helpers/config';
import { Button } from 'bv-components';
import classNames from 'classnames';
import { useLoggedIn } from 'bv-hooks';
import { useEffect, useState } from 'react';
import { addFilterTag } from 'CasinoGlobal/redux/all_categories_filter/thunk';
import { resetAll, storeLobbyTypes } from 'CasinoGlobal/redux/all_categories_filter/action_creators';
import { resetFilteredGames } from 'CasinoV2/ducks/casino_store';
import { isFilterResultsPage } from 'CasinoV2/helpers/location';
import { getSelectedFilterTagsStorage, selectedFilterTagsStorageHandler } from '../all_categories_page/helpers/filterTagsStorageHandlers';
import CasinoFilterModal from '../all_categories_page/filter_modal';

const AllCategoriesButton = () => {
  const [showModal, setShowModal] = useState(false);
  const { pathname } = useLocation();
  const lobbyTag = getTag();

  const selectedFilterTags = useSelector((state) => selectSelectedFilterTags(state));
  const selectedFilterTagsInUrl = useSelector((state) => selectFilterTagsInUrl(state));

  const allCategoriesClass = classNames('casino-search-nav__option-icon all-categories__icon', {
    'has-notification': selectedFilterTags[lobbyTag]?.length,
  });

  const dispatch = useDispatch();
  const loggedIn = useLoggedIn();

  const isResultPage = isFilterResultsPage(pathname);

  useEffect(() => {
    const selectedFilterTagsStorage = getSelectedFilterTagsStorage(loggedIn, lobbyTag);

    if (!selectedFilterTags[lobbyTag]?.length
      && selectedFilterTagsStorage.length
      && !isResultPage) {
      dispatch(storeLobbyTypes(lobbyTag));
      dispatch(addFilterTag(selectedFilterTagsStorage, lobbyTag));
    }

    if (!selectedFilterTags[lobbyTag]?.length && selectedFilterTagsInUrl.length && isResultPage) {
      dispatch(storeLobbyTypes(lobbyTag));
      dispatch(addFilterTag(selectedFilterTagsInUrl, lobbyTag));
    }

    if (!isResultPage && selectedFilterTagsStorage.length) {
      selectedFilterTagsStorageHandler(selectedFilterTagsStorage, loggedIn, lobbyTag);
    }

    if (isResultPage && selectedFilterTagsInUrl.length) {
      selectedFilterTagsStorageHandler(selectedFilterTagsInUrl, loggedIn, lobbyTag);
    }
  }, [loggedIn, lobbyTag]);

  const history = useHistory();
  const onCloseModalHandler = () => {
    setShowModal(false);

    // by closing modal on the result page redirect to the main casino-type page
    // and reset filter if no selectedFilterTags
    if (isResultPage && !selectedFilterTags[lobbyTag]?.length) {
      dispatch(resetAll(lobbyTag));
      dispatch(resetFilteredGames());
      history.push(`/${getSlug()}/`);
    }
  };

  return (
    <>
      <Button
        className="casino-search-nav__option"
        aria-label="All Categories"
        onClick={() => setShowModal(true)}
        noClass
      >
        <span className={allCategoriesClass} />
        {!isResultPage && t('all_categories')}
      </Button>

      {showModal && <CasinoFilterModal onClose={onCloseModalHandler} />}
    </>
  );
};

export default AllCategoriesButton;
