import PropTypes from 'prop-types';

import { Carousel } from 'bv-components';
import AppliedFilterTag from './applied_filter_tag';

const AppliedFilterTags = ({ appliedFilterTags }) => (
  <div className="bvs-applied-filter-tags">
    <Carousel scrollStep={234} embedded largeArrows>
      {appliedFilterTags.map((filterTag) => (
        <AppliedFilterTag {...filterTag} key={filterTag.id} />
      ))}
    </Carousel>
  </div>
);

AppliedFilterTags.propTypes = {
  appliedFilterTags: PropTypes.instanceOf(Array).isRequired,
};

export default AppliedFilterTags;
