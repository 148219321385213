const FILTER_RESULT_PAGE_REGEX = /filter-result$/;
const GAME_INFO_PAGE_REGEX = /casino\/games\/[0-9]+-[a-z0-9-]+$/;

export const isFilterResultsPage = (pathname) => FILTER_RESULT_PAGE_REGEX.test(pathname);

export const isGameInfoPage = (pathname) => GAME_INFO_PAGE_REGEX.test(pathname);

export const showBannerContent = (pathname) => (
  !isFilterResultsPage(pathname) && !isGameInfoPage(pathname)
);
