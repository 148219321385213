import { t } from 'bv-i18n';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectCurrentLobby, selectFilterResult, selectSelectedFilterTags } from 'CasinoGlobal/redux/all_categories_filter/selectors';
import { Button } from 'bv-components';
import { getSlug } from 'CasinoV2/helpers/config';

import PropTypes from 'prop-types';

const FilterShowResults = ({ closeModal }) => {
  const history = useHistory();
  const currentLobby = useSelector((state) => selectCurrentLobby(state));
  const filterResult = useSelector((state) => selectFilterResult(state));
  const filterResultCount = filterResult[currentLobby]?.count;
  const selectedFilterTags = useSelector((state) => selectSelectedFilterTags(state));

  const searchParams = selectedFilterTags[currentLobby]?.length
    ? `?filterTags=${selectedFilterTags[currentLobby].join(',')}`
    : '';

  const onClickHandler = () => {
    history.push(`/${getSlug()}/all-categories-filter-result${searchParams}`);
    closeModal();
  };
  const resultText = filterResultCount === 1
    ? t('casino.all_categories_filter.result')
    : t('casino.all_categories_filter.results');

  return (
    <Button
      primary
      onClick={onClickHandler}
      disabled={!filterResult[currentLobby]?.count}
    >
      {`${t('casino.all_categories_filter.show')} ${filterResult[currentLobby]?.count} ${resultText}`}
    </Button>
  );
};

FilterShowResults.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default FilterShowResults;
