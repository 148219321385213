import PropTypes from 'prop-types';
import FilterResetAll from './filter_reset_all';
import FilterShowResults from './filter_show_results';

const FilterFooter = ({ closeModal }) => (
  <div className="casino-filter-footer">
    <FilterResetAll />
    <FilterShowResults closeModal={closeModal} />
  </div>
);

FilterFooter.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default FilterFooter;
