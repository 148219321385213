import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { MessageBox } from 'bv-components';

const EmptyCategories = ({ onCloseClick }) => {
  const actions = [{
    danger: true,
    label: t('close'),
    onClick: onCloseClick,
  }];

  return (
    <MessageBox
      className="casino-filter-empty"
      danger
      iconClosable={false}
      actions={actions}
    >
      <p>{t('casino.all_categories_filter.no_categories')}</p>
    </MessageBox>
  );
};

EmptyCategories.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
};

export default EmptyCategories;
