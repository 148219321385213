import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
import Banners from 'bv-banners';
import {
  selectIsFetching,
  selectCatalogCategories,
} from 'CasinoV2/selectors/casino_selector';
import getCategoryPath from 'CasinoV2/helpers/get_category_path';
import {
  BannerSkeleton,
  NavigationSkeleton,
  MainContentSkeleton,
} from 'CasinoV2/helpers/skeletons';
import { showBannerContent as getShowBannerContent } from 'CasinoV2/helpers/location';
import { getSlug, getBannersLocation } from 'CasinoV2/helpers/config';
import { GameLaunch, FtpGameLaunch } from 'CasinoV2/lazy_components';
import { AuthenticatedRoute } from 'Index/features/routing/components/shared/index';
import Search from './search_page';
import Navigation from './navigation';
import Suppliers from './filters/suppliers';
import CategoryPage from './category_page';
import GamePage from './game_page';
import SearchBar from './search_bar';
import FavoritesPage from './favorites_page';
import AllCategoriesFilterResultPage from './all_categories_filter_result_page';

const CategoryWithProps = (category, isHome = false) => () => [
  !isHome && <Suppliers />,
  <CategoryPage category={category} />,
];

const App = ({
  isFetching,
  categories,
  contentElRef,
}) => {
  const { pathname } = useLocation();
  const bannersLocation = getBannersLocation();
  const showBannerContent = getShowBannerContent(pathname);

  const showBanners = (showBannerContent && !!bannersLocation);

  return (
    <>
      {showBanners && (
        <div className="site-top-banner-wrapper">
          <div className="site-top-banner-wrapper">
            {isFetching ? (
              <BannerSkeleton />
            ) : (
              <Banners location={bannersLocation} />
            )}
          </div>
        </div>
      )}
      <div ref={contentElRef}>
        {showBannerContent
          && (isFetching ? <NavigationSkeleton /> : <SearchBar />)}
        {showBannerContent
          && (isFetching ? <NavigationSkeleton /> : <Navigation />)}
        {isFetching ? (
          <MainContentSkeleton />
        ) : (
          <section className="casino-v2-wrapper">
            <Switch>
              <Route path={`/${getSlug()}/ftp-games/:gameId/launch`} component={FtpGameLaunch} />
              <Route path={`/${getSlug()}/games/:gameId/launch`} component={GameLaunch} />
              <Route path={`/${getSlug()}/games/:gameId`} component={GamePage} />
              <Route path={`/${getSlug()}/search`} component={Search} />
              <AuthenticatedRoute path={`/${getSlug()}/favorites`} feature="favourite_casino_games">
                <FavoritesPage />
              </AuthenticatedRoute>
              {categories.length && (
                <Route exact path={['/', `/${getSlug()}`]} render={CategoryWithProps(categories[0], true)} />
              )}
              {categories.map((category) => (
                <Route path={getCategoryPath(category)} render={CategoryWithProps(category)} />
              ))}
              <Route path={`/${getSlug()}/all-categories-filter-result`} component={AllCategoriesFilterResultPage} />
            </Switch>
          </section>
        )}
      </div>
    </>
  );
};

App.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  categories: PropTypes.instanceOf(Array).isRequired,
  contentElRef: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state) => ({
  isFetching: selectIsFetching(state),
  categories: selectCatalogCategories(state),
});

export default connect(mapStateToProps)(App);
