import { Skeleton, Spinner } from 'bv-components';
import { useLocation } from 'react-router-dom';

export const BannerSkeleton = () => (
  <Skeleton skeletonType="site-main-banner-wrapper" hasHeader={false} />
);

export const NavigationSkeleton = () => (
  <Skeleton skeletonType="secondary-navigation" hasHeader={false} />
);

export const GamesListSkeleton = () => (
  <Skeleton skeletonType="casino-wrapper" />
);

export const MainContentSkeleton = () => {
  const { pathname } = useLocation();

  return pathname.includes('/launch')
    ? <Spinner />
    : <GamesListSkeleton />;
};
