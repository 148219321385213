import { HOUR_IN_MILLIS } from 'bv-helpers/datetime';

const LOC_STOR_NAME = 'selectedFilterTags';
const locStor = window.localStorage;
const setLocStor = (dataObj) => locStor.setItem(LOC_STOR_NAME, JSON.stringify(dataObj));
const removeLocStor = () => locStor.removeItem(LOC_STOR_NAME);
const getLocStor = () => locStor.getItem(LOC_STOR_NAME);
const getParsedLocStor = () => {
  const filterTagsStorage = getLocStor();

  return filterTagsStorage ? JSON.parse(filterTagsStorage) : null;
};

export const getSelectedFilterTagsStorage = (isLoggedIn, lobby) => {
  const expireTerm = isLoggedIn ? HOUR_IN_MILLIS : HOUR_IN_MILLIS / 2;

  let parsedFilterTagsStorage = getParsedLocStor();

  const diffTermInMillis = parsedFilterTagsStorage
    ? (Date.now() - new Date(parsedFilterTagsStorage.setTime))
    : null;

  if ((diffTermInMillis && diffTermInMillis > expireTerm)
    || (!isLoggedIn && parsedFilterTagsStorage?.isLoggedIn)) {
    removeLocStor();
    parsedFilterTagsStorage = {};
  }

  return parsedFilterTagsStorage?.[lobby] || [];
};

export const selectedFilterTagsStorageHandler = (fTags, status, lobby) => {
  const prevStorageValue = getParsedLocStor();

  if (fTags?.length) {
    setLocStor({
      ...prevStorageValue,
      [lobby]: fTags,
      setTime: new Date(),
      isLoggedIn: status,
    });
  } else if (!fTags?.length && prevStorageValue) {
    setLocStor({
      ...prevStorageValue,
      [lobby]: [],
      setTime: new Date(),
      isLoggedIn: status,
    });
  }
};
