import { useDispatch, useSelector, connect } from 'react-redux';
import { compose } from 'underscore';
import { useEffect, useState } from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';

import PropTypes from 'prop-types';

import { GamesListSkeleton } from 'CasinoV2/helpers/skeletons';
import { GamesList } from 'casino-components';
import { t } from 'bv-i18n';

import { getCasinoFilters } from 'CasinoGlobal/redux/all_categories_filter/thunk';
import { useLoggedIn } from 'bv-hooks';
import { selectFilterGroups, selectFilterTagsInUrl } from 'CasinoGlobal/redux/all_categories_filter/selectors';

import {
  getCasinoFilteredGames,
  fetchMore as getMoreCasinoFilteredGames,
} from 'CasinoV2/ducks/casino_thunks';
import { selectFilteredGames } from 'CasinoV2/selectors/casino_selector';

import { storeFilterTagsInUrl } from 'CasinoGlobal/redux/all_categories_filter/action_creators';
import { v as bvVar } from 'bv';
import { getSlug, setConfig } from 'CasinoV2/helpers/config';
import AllCategoriesButton from '../search_bar/all_categories_button';

import { getFilterTagsFromUrl, getFilterTagsToParse } from './helpers/index';

import AppliedFilterTags from './components/applied_filter_tags';

const CasinoFilterResult = ({
  games, gameInstances, totalEntries, isGamesFetching, fetchMore,
}) => {
  const { path } = useRouteMatch();
  const lobbyConfig = bvVar('casinoLobbies').find((lobby) => path.startsWith(`/${lobby.slug}`),
  );

  setConfig(lobbyConfig);

  const lobbyTag = lobbyConfig.tag;
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedIn = useLoggedIn();

  const filterGroups = useSelector((state) => selectFilterGroups(state));

  const selectedFilterTagsInUrl = useSelector((state) => selectFilterTagsInUrl(state));

  const [filterTagsToParse, setFilterTagsToPars] = useState(
    getFilterTagsToParse(filterGroups, selectedFilterTagsInUrl));

  const filterTagsInUrl = getFilterTagsFromUrl(search);

  const filterGroupsWithSelectedTags = filterGroups.filter(
    (group) => (group.filterTags.filter((tag) => filterTagsInUrl.includes(tag.id)).length))
    .map((fGroup) => ({ id: fGroup.id, title: fGroup.label || fGroup.name })) || [];
  const selectedTagsWithNames = filterTagsToParse
    .map((fTag) => ({ id: fTag.id, title: fTag.label || fTag.name })) || [];

  const tracking = {
    path: 'filters',
    lobby: lobbyTag,
    filterGroups: filterGroupsWithSelectedTags,
    filterTags: selectedTagsWithNames,
  };

  useEffect(() => {
    if (!filterGroups.length) dispatch(getCasinoFilters(lobbyTag)); // FILTERGROUPS
  }, [loggedIn, lobbyTag]);

  useEffect(() => {
    dispatch(storeFilterTagsInUrl(filterTagsInUrl));

    dispatch(getCasinoFilteredGames(filterTagsInUrl)); // GAMES

    window.scrollTo(0, 0); // scrolling to the top when the new filter is applied
  }, [search, loggedIn, lobbyTag]);

  useEffect(() => {
    setFilterTagsToPars(getFilterTagsToParse(filterGroups, selectedFilterTagsInUrl));
  }, [filterGroups, selectedFilterTagsInUrl, search, lobbyTag]);

  if (isGamesFetching && !games.length) return <GamesListSkeleton />;

  if (!filterTagsInUrl.length) { // redirect to main casino-type page
    history.push(`/${getSlug()}/`);
  }

  return (
    <div className="casino-categories-result">
      <div className="casino-categories-result__header">
        <h2 className="bvs-header">
          {t('results')}
          {` (${totalEntries})`}
        </h2>
        <AllCategoriesButton />
      </div>

      {filterTagsToParse.length ? (
        <div className="casino-categories-result__tags">
          <AppliedFilterTags appliedFilterTags={filterTagsToParse} />
        </div>
      ) : null}

      {games.length > 0 ? (
        <GamesList
          games={games}
          gameInstances={gameInstances}
          onEndReached={() => fetchMore(filterTagsInUrl)}
          tracking={tracking}
        />
      ) : null}
    </div>
  );
};

CasinoFilterResult.propTypes = {
  isGamesFetching: PropTypes.bool.isRequired,
  games: PropTypes.instanceOf(Array).isRequired,
  gameInstances: PropTypes.instanceOf(Array).isRequired,
  totalEntries: PropTypes.number.isRequired,
  fetchMore: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  filter: compose(dispatch, getCasinoFilteredGames),
  fetchMore: compose(dispatch, getMoreCasinoFilteredGames),
});

export default connect(selectFilteredGames, mapDispatchToProps)(CasinoFilterResult);
