import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { addMiddleware } from 'redux-dynamic-middlewares';
import Context from 'casino-context';
import { v as bvVar } from 'bv';
import { useRouteMatch } from 'react-router-dom';
import { ContentLayout } from 'bv-components';

import { useLocation } from 'react-router';
import casinoStore from './ducks/casino_store';
import casinoMiddleware from './middleware';
import AppRedux from './components/app_redux';
import { setConfig } from './helpers/config';
import { showBannerContent } from './helpers/location';

const { addReducers, store } = window.reduxState;

if (!store.getState().casinoStore) {
  addReducers({
    casinoStore,
  });

  addMiddleware(
    casinoMiddleware,
  );
}

const CasinoLobbyApp = ({ lobbyTag }) => {
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const lobbyConfig = bvVar('casinoLobbies').find((lobby) => (
    lobby.tag === lobbyTag || path.startsWith(`/${lobby.slug}`)
  ));

  setConfig(lobbyConfig);

  const contextValue = useMemo(() => ({ slug: lobbyConfig.slug }), [lobbyConfig.slug]);

  const showTabs = showBannerContent(pathname);

  return (
    <ContentLayout showTabs={showTabs}>
      <Context.Provider value={contextValue}>
        <AppRedux />
      </Context.Provider>
    </ContentLayout>
  );
};

CasinoLobbyApp.propTypes = {
  lobbyTag: PropTypes.string,
};

CasinoLobbyApp.defaultProps = {
  lobbyTag: null,
};

export default CasinoLobbyApp;
