import { t } from 'bv-i18n';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentLobby, selectSelectedFilterTags } from 'CasinoGlobal/redux/all_categories_filter/selectors';
import classnames from 'classnames';
import { deselectFilterTag, storeFilterResult } from 'CasinoGlobal/redux/all_categories_filter/action_creators';

const FilterResetAll = () => {
  const dispatch = useDispatch();
  const currentLobby = useSelector((state) => selectCurrentLobby(state));
  const selectedFilterTags = useSelector((state) => selectSelectedFilterTags(state));
  const onClickHandler = () => {
    dispatch(deselectFilterTag(selectedFilterTags[currentLobby], currentLobby));
    dispatch(storeFilterResult({ count: 0 }, currentLobby));
  };

  return (
    <button
      type="button"
      onClick={onClickHandler}
      className={classnames('casino-filter-footer__reset', {
        disabled: !selectedFilterTags[currentLobby]?.length,
      })}
    >
      {t('reset_all')}
    </button>
  );
};

export default FilterResetAll;
