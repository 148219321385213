import { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { createStructuredSelector } from 'reselect';
import { t } from 'bv-i18n';

import { Button, ButtonCardWithArrow } from 'bv-components';
import { toggleSupplier } from 'CasinoV2/ducks/casino_store';
import { selectSuppliers, selectSelectedSuppliers, selectShowSuppliersFilter } from 'CasinoV2/selectors/casino_selector';

const Suppliers = ({
  showSuppliersFilter, toggleSupplier: toggleSupplierFn, suppliers, selectedSuppliers,
}) => {
  const [boxOpen, toggleBox] = useState(false);

  if (!showSuppliersFilter) return null;

  const toggleSupplierList = () => toggleBox(!boxOpen);
  const onClick = (item) => () => {
    toggleSupplierFn(item);
  };

  return (
    <div className="casino-filters">
      <ButtonCardWithArrow
        className="casino-filters__button"
        onClick={toggleSupplierList}
        label={t('javascript.games_list.suppliers')}
        open={boxOpen}
      />
      {boxOpen && (
        <>
          <div className="casino-filters__blocker" />
          ,
          <div className="bvs-card casino-filters__list">
            {suppliers.map((item) => (
              <Button
                key={item}
                label={item}
                onClick={onClick(item)}
                className={classnames(
                  'casino-filters__list-item',
                  { 'is-active': selectedSuppliers.includes(item) },
                )}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

Suppliers.propTypes = {
  suppliers: PropTypes.instanceOf(Array).isRequired,
  selectedSuppliers: PropTypes.instanceOf(Array).isRequired,
  toggleSupplier: PropTypes.func.isRequired,
  showSuppliersFilter: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  suppliers: selectSuppliers,
  selectedSuppliers: selectSelectedSuppliers,
  showSuppliersFilter: selectShowSuppliersFilter,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSupplier: compose(dispatch, toggleSupplier),
});

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);
