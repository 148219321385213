import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, debounce } from 'underscore';

import { GamesListSkeleton } from 'CasinoV2/helpers/skeletons';
import { GamesList } from 'casino-components';
import { t } from 'bv-i18n';

import { selectSearch } from 'CasinoV2/selectors/casino_selector';
import { searchGames, searchMore as searchMoreAction } from 'CasinoV2/ducks/casino_thunks';
import { resetSearch as resetSearchAction } from 'CasinoV2/ducks/casino_store';
import getSearchTerm from 'CasinoV2/helpers/get_search_term';

const Search = ({
  location: { search: locationSearch },
  fetching,
  games,
  gameInstances,
  totalEntries,
  search,
  resetSearch,
  searchMore,
}) => {
  const searchTerm = getSearchTerm(locationSearch);

  const throttleSearch = useCallback(debounce(search, 500), [search]);

  const tracking = { path: 'search' };

  useEffect(() => {
    throttleSearch(searchTerm);
  }, [searchTerm, throttleSearch]);

  // When the component is unmounted, search data should be removed
  useEffect(() => () => {
    resetSearch();
    throttleSearch.cancel();
  }, []);

  if (fetching && !games.length) return <GamesListSkeleton />;

  return (
    <>
      <p className="casino-games__results">
        {games.length
          ? t('javascript.casino.number_of_results', { count: totalEntries })
          : t('javascript.casino.your_search_results')}
      </p>
      {games.length > 0 && (
        <GamesList
          games={games}
          gameInstances={gameInstances}
          onEndReached={() => searchMore(searchTerm)}
          tracking={tracking}
        />
      )}
    </>
  );
};

Search.propTypes = {
  fetching: PropTypes.bool.isRequired,
  games: PropTypes.instanceOf(Array).isRequired,
  gameInstances: PropTypes.instanceOf(Array).isRequired,
  totalEntries: PropTypes.number.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  search: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  searchMore: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  search: compose(dispatch, searchGames('casino')),
  resetSearch: compose(dispatch, resetSearchAction('casino')),
  searchMore: compose(dispatch, searchMoreAction('casino')),
});

export default connect(selectSearch('casino'), mapDispatchToProps)(Search);
