import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import { linkLocalizer } from 'bv-helpers/locale';
import PropTypes from 'prop-types';

const StickyButtons = ({ url, onClick, loggedIn }) => (
  <div className="bvs-card casino-game-info__sticky-button-wrapper">
    {
      loggedIn ? (
        <Button
          primary
          className="casino-game-info__sticky-button"
          label={t('javascript.casino.play_now')}
          href={url}
          onClick={onClick}
        />
      ) : (
        <>
          <Button
            primary
            noClass
            className="bvs-button-alternative casino-game-info__sticky-button"
            label={t('login')}
            href={linkLocalizer('/login')}
          />
          <Button
            primary
            className="casino-game-info__sticky-button"
            label={t('open_account')}
            href={linkLocalizer('/account/new')}
          />
        </>
      )
    }
  </div>
);

StickyButtons.propTypes = {
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};

export default StickyButtons;
