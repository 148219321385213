import { fetchFilters, fetchNumberOfResults } from 'CasinoGlobal/components/casino_filter/api';
import {
  fetchFilterGroups,
  storeFilterGroups,
  selectFilterTag,
  deselectFilterTag,
  storeFilterResult,
} from './action_creators';
import { selectFetchingStatus, selectSelectedFilterTags } from './selectors';

export const addFilterTag = (filterTagId, lobbyTag) => (dispatch, getState) => {
  dispatch(selectFilterTag(filterTagId, lobbyTag));

  const state = getState();
  const selectedFilterTags = selectSelectedFilterTags(state);

  fetchNumberOfResults(selectedFilterTags[lobbyTag])
    .then((countObj) => dispatch(storeFilterResult(countObj, lobbyTag)));
};

export const removeFilterTag = (filterTagId, lobbyTag) => (dispatch, getState) => {
  dispatch(deselectFilterTag(filterTagId, lobbyTag));

  const state = getState();
  const selectedFilterTags = selectSelectedFilterTags(state);

  if (selectedFilterTags[lobbyTag]?.length) {
    fetchNumberOfResults(selectedFilterTags[lobbyTag])
      .then((countObj) => dispatch(storeFilterResult(countObj, lobbyTag)));
  } else {
    dispatch(storeFilterResult({ count: 0 }, lobbyTag));
  }
};

export const getCasinoFilters = (lobbyTag) => (dispatch, getState) => {
  const state = getState();
  const { isFetching } = selectFetchingStatus(state);

  if (!isFetching) {
    dispatch(fetchFilterGroups());

    fetchFilters(lobbyTag)
      .then(
        (filterGroups) => dispatch(storeFilterGroups(filterGroups, lobbyTag)));
  }
};
