import PropTypes from 'prop-types';

import { GamesList } from 'casino-components';

const LiveCasinoGamesListComponent = ({ label, ...gamesListProps }) => (
  <>
    <h2 className="bvs-header">{label}</h2>
    <GamesList className="live-casino-games" {...gamesListProps} liveCasinoGame />
  </>
);

LiveCasinoGamesListComponent.propTypes = {
  label: PropTypes.string.isRequired,
};

export default LiveCasinoGamesListComponent;
