import {
  useContext, useEffect, useState, useMemo,
} from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { useFetch, useLoggedIn, useFeature } from 'bv-hooks';
import { Spinner, Image } from 'bv-components';
import { ScrollableContext } from 'bv-contexts';

import { cmsAssetPath } from 'bv';
import { withScrollToTop } from 'bv-hocs';
import { fetchGameDetailsJson } from 'CasinoV2/api';
import { getGameLaunchHref } from 'casino-helpers';
import { getSlug } from 'CasinoV2/helpers/config';
import setMetaInfo from 'CasinoGlobal/services/set_casino_game_meta_info';
import { selectSeoEntity } from 'CasinoV2/selectors/casino_selector';
import FavoriteButton from 'CasinoGlobal/components/game/components/favorite_button';
import { selectFavorite } from 'CasinoGlobal/redux/favorites/selectors';
import { getFavoriteGames } from 'CasinoGlobal/redux/favorites/thunk';
import MissingGame from './missing_game';
import StickyButtons from './sticky_buttons';

const GamePage = ({ match: { params: { gameId } }, seoEntity }) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [imgError, setImgError] = useState(false);
  const scrollableRef = useContext(ScrollableContext);

  const gameClassName = classNames('casino-game', 'img-lazy', {
    'img-loaded': imgLoaded,
    'img-error': imgError,
  });

  // Fetch game details
  const [game, loading] = useFetch(() => (
    fetchGameDetailsJson(gameId)
  ), [gameId]);

  const loggedIn = useLoggedIn();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (game) {
      setMetaInfo(game);
    }
  }, [game]);

  const [favoritesEnabled] = useFeature('favourite_casino_games');

  const selectFavoritesById = useMemo(selectFavorite, []);
  const isFavorite = useSelector((state) => selectFavoritesById(state, game?.id));

  useEffect(() => {
    if (loggedIn && favoritesEnabled) {
      dispatch(getFavoriteGames());
    }
  }, [loggedIn, favoritesEnabled]);

  if (loading) return <Spinner />;

  if (!game) {
    return <MissingGame />;
  }

  const url = getGameLaunchHref(game, getSlug());

  const launch = () => history.push(url);

  return (
    <div className="casino-game-info">
      <h1 className="bvs-header casino-game-info__title">{seoEntity.heading || game.title}</h1>
      <div className="bvs-card casino-game-info__card" onClick={launch}>

        <div className="casino-game-info__card-bg" style={{ backgroundImage: `url(${cmsAssetPath(game.regularImage)})` }} />

        <div className="casino-game-info__card-content">
          <div className="casino-game-info__card-image">
            <div className={gameClassName}>
              <div className="casino-game__img">
                <Image
                  className="casino-game__bg"
                  src={cmsAssetPath(game.regularImage)}
                  webpSrc={cmsAssetPath(game.regularImageWebp)}
                  onLoad={() => { setImgLoaded(true); }}
                  onError={() => { setImgError(true); }}
                  scrollableRef={scrollableRef}
                  isVideoTile={false}
                  isLazyImage={false}
                  webp={!!game.regularImageWebp}
                />
              </div>
              {loggedIn && favoritesEnabled && (
                <FavoriteButton
                  gameId={game.id}
                  isFavorite={isFavorite}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <StickyButtons urt={url} onClick={launch} loggedIn={loggedIn} />
    </div>
  );
};

GamePage.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  seoEntity: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = createStructuredSelector({
  seoEntity: selectSeoEntity,
});

export default connect(mapStateToProps)(withScrollToTop()(GamePage));
