import { MessageBox } from 'bv-components';
import { t } from 'bv-i18n';

const MissingGame = () => (
  <MessageBox actions={[{ label: t('javascript.account_form.action_buttons.go_to_casino'), to: '/casino', primary: true }]} icon={false}>
    <p>{t('javascript.casino.game_unavailable')}</p>
  </MessageBox>
);

export default MissingGame;
