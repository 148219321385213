import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeFilterTag } from 'CasinoGlobal/redux/all_categories_filter/thunk';
import PropTypes from 'prop-types';
import { selectCurrentLobby, selectFilterTagsInUrl } from 'CasinoGlobal/redux/all_categories_filter/selectors';
import { storeFilterTagsInUrl } from 'CasinoGlobal/redux/all_categories_filter/action_creators';
import { selectedFilterTagsStorageHandler } from 'CasinoV2/components/all_categories_page/helpers/filterTagsStorageHandlers';
import { isFilterResultsPage } from 'CasinoV2/helpers/location';
import classnames from 'classnames';
import { useLocation } from 'react-router';
import { resetFilteredGames } from 'CasinoV2/ducks/casino_store';
import { useLoggedIn } from 'bv-hooks';
import { getSlug } from 'CasinoV2/helpers/config';

const AppliedFilterTag = ({ id, name, label }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentLobby = useSelector((state) => selectCurrentLobby(state));
  const selectedFilterTagsInUrl = useSelector((state) => selectFilterTagsInUrl(state));
  const loggedIn = useLoggedIn();
  const filterTagsClickHandler = () => {
    const searchParams = selectedFilterTagsInUrl.filter((i) => i !== id);

    dispatch(storeFilterTagsInUrl(searchParams));
    dispatch(removeFilterTag(id, currentLobby));
    selectedFilterTagsStorageHandler(searchParams, loggedIn, currentLobby);

    if (searchParams.length) {
      history.push(`/${getSlug()}/all-categories-filter-result${searchParams.length
        ? `?filterTags=${searchParams.join(',')}`
        : ''}`);
    } else {
      dispatch(resetFilteredGames());
      history.push(`/${getSlug()}/`);
    }
  };

  return (
    <div className="casino-filter__tag casino-applied-filter-tag">
      <span
        className={classnames('bvs-button-filter-chip', {
          active: true,
          'has-remove': isFilterResultsPage(pathname),
        })}
        onClick={filterTagsClickHandler}
      >
        {label || name}
      </span>
    </div>
  );
};

AppliedFilterTag.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default AppliedFilterTag;
