import { setMetaInfo } from 'bv-services/seo';

export default (game) => {
  setMetaInfo(() => {
    if (!game.id) return null;

    return ({
      id: game.id,
      type: 'game',
      data: {
        ...game,
      },
    });
  });
};
