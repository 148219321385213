import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  selectFetchingStatus,
  selectFilterGroups,
  selectSelectedFilterTags,
} from 'CasinoGlobal/redux/all_categories_filter/selectors';
import { useEffect } from 'react';

import { addFilterTag, getCasinoFilters } from 'CasinoGlobal/redux/all_categories_filter/thunk';
import { useLoggedIn } from 'bv-hooks';

import { Spinner } from 'bv-components';
import { getTag } from 'CasinoV2/helpers/config';
import { storeLobbyTypes } from 'CasinoGlobal/redux/all_categories_filter/action_creators';
import FilterGroup from './components/filter_group';

import FilterFooter from './components/footer/filter_footer';
import { getSelectedFilterTagsStorage, selectedFilterTagsStorageHandler } from './helpers/filterTagsStorageHandlers';
import EmptyCategories from './empty_categories';

const Filter = ({ closeModal }) => {
  const currentLobby = getTag();

  const { isFetching } = useSelector((state) => selectFetchingStatus(state));
  const filterGroups = useSelector((state) => selectFilterGroups(state));

  const dispatch = useDispatch();
  const loggedIn = useLoggedIn();

  const selectedFilterTags = useSelector((state) => selectSelectedFilterTags(state));

  useEffect(() => {
    const selectedFilterTagsStorage = getSelectedFilterTagsStorage(loggedIn, currentLobby);

    if (!selectedFilterTags[currentLobby]?.length && selectedFilterTagsStorage.length) {
      dispatch(storeLobbyTypes(currentLobby));
      dispatch(addFilterTag(selectedFilterTagsStorage, currentLobby));
    }

    dispatch(getCasinoFilters(currentLobby));
  }, [loggedIn, currentLobby]);

  useEffect(() => {
    selectedFilterTagsStorageHandler(selectedFilterTags[currentLobby], loggedIn, currentLobby);
  }, [selectedFilterTags[currentLobby]]);

  if (isFetching) return <Spinner />;

  return (
    <>
      <div className="casino-filter has-scroll-bars">
        {filterGroups?.length ? (
          filterGroups.map((filterGroup) => (
            <FilterGroup {...filterGroup} key={filterGroup.id} />
          ))
        ) : (
          <EmptyCategories onCloseClick={closeModal} />
        )}
      </div>
      {filterGroups?.length && (<FilterFooter closeModal={closeModal} />)}
    </>
  );
};

Filter.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default Filter;
