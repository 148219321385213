import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FilterTag from './filter_tag';
import SuppliersSeeAllLess from './suppliers_see_all_less';

const FilterTags = ({ filterTags, isExpandable, isSuppliers }) => {
  const [seeAll, setSeeAll] = useState(true);
  const handleClick = () => setSeeAll(!seeAll);

  return (
    <div
      className={classNames('casino-filter__tags', {
        'is-suppliers': isSuppliers,
        'is-expandable': isExpandable,
        'is-see-all': !seeAll,
      })}
    >
      {filterTags.map((filterTag) => (
        <FilterTag {...filterTag} key={filterTag.id} />
      ))}
      {isExpandable && filterTags.length > 8 ? (
        <SuppliersSeeAllLess seeAll={seeAll} onClick={handleClick} />
      ) : null}
    </div>
  );
};

FilterTags.propTypes = {
  filterTags: PropTypes.instanceOf(Array).isRequired,
  isSuppliers: PropTypes.bool.isRequired,
  isExpandable: PropTypes.bool.isRequired,
};

export default FilterTags;
