import { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { createStructuredSelector } from 'reselect';
import { fetchComponents, filterBySuppliers } from 'CasinoV2/ducks/casino_thunks';
import {
  selectComponents,
  selectSelectedSuppliers,
  selectIsFetchingComponents,
  selectSeoEntity,
} from 'CasinoV2/selectors/casino_selector';
import setMetaInfo from 'CasinoGlobal/services/set_casino_category_meta_info';
import { resetComponents, resetFilters } from 'CasinoV2/ducks/casino_store';
import ScrollingContext from 'CasinoV2/components/scrolling_context';
import { contentScrolled, scrollToContentTop } from 'CasinoV2/helpers/scrolling';
import { GamesListSkeleton } from 'CasinoV2/helpers/skeletons';
import ComponentWrapper from './component_wrapper';

const CategoryPage = ({
  category: casinoCategory,
  suppliers,
  fetching,
  components,
  filterBySuppliers: filterBySuppliersFn,
  resetComponents: resetComponentsFn,
  resetFilters: resetFiltersFn,
  fetchComponents: fetchComponentsFn,
  seoEntity,
}) => {
  const prevCategory = useRef(null);

  const { url: categoryUrl } = casinoCategory;

  useEffect(() => {
    if (prevCategory.current !== categoryUrl) {
      prevCategory.current = categoryUrl;

      // When a category changes, the filters are reset
      // By changing the filters, the components are already fetched
      if (!suppliers.length) fetchComponentsFn(categoryUrl);
    } else {
      filterBySuppliersFn(categoryUrl, suppliers);
    }
  }, [categoryUrl, suppliers.join('')]);

  // Reset the components when page is unmounted
  useEffect(() => resetComponentsFn, []);

  useEffect(() => {
    if (categoryUrl) {
      setMetaInfo(casinoCategory);
    }
  }, [categoryUrl]);

  const scrollingValues = useContext(ScrollingContext);

  // Reset the filters when moving to a different category
  useEffect(() => {
    if (contentScrolled(scrollingValues)) scrollToContentTop(scrollingValues);

    return resetFiltersFn;
  }, [categoryUrl]);

  if (fetching) return <GamesListSkeleton />;

  return (
    <div className="casino-games__list">
      {seoEntity.heading && (
        <h1 className="bvs-header">{seoEntity.heading}</h1>
      )}
      {components.map((id) => (
        <ComponentWrapper id={id} key={id} path={categoryUrl} />
      ))}
    </div>
  );
};

CategoryPage.propTypes = {
  fetchComponents: PropTypes.func.isRequired,
  filterBySuppliers: PropTypes.func.isRequired,
  resetComponents: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  components: PropTypes.instanceOf(Array).isRequired,
  suppliers: PropTypes.instanceOf(Array).isRequired,
  category: PropTypes.instanceOf(Object).isRequired,
  fetching: PropTypes.bool.isRequired,
  seoEntity: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = createStructuredSelector({
  components: selectComponents,
  suppliers: selectSelectedSuppliers,
  fetching: selectIsFetchingComponents,
  seoEntity: selectSeoEntity,
});

const mapDispatchToProps = (dispatch) => ({
  fetchComponents: compose(dispatch, fetchComponents),
  filterBySuppliers: compose(dispatch, filterBySuppliers),
  resetComponents: compose(dispatch, resetComponents),
  resetFilters: compose(dispatch, resetFilters),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);
