import { useDispatch, useSelector } from 'react-redux';
import { addFilterTag, removeFilterTag } from 'CasinoGlobal/redux/all_categories_filter/thunk';
import { selectCurrentLobby, selectSelectedFilterTags } from 'CasinoGlobal/redux/all_categories_filter/selectors';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const FilterTag = ({ id, label, name }) => {
  const dispatch = useDispatch();
  const currentLobby = useSelector((state) => selectCurrentLobby(state));
  const selectedFilterTags = useSelector((state) => selectSelectedFilterTags(state));
  const isFilterTagSelected = selectedFilterTags[currentLobby]?.includes(id);
  const filterTagsClickHandler = () => {
    dispatch(!isFilterTagSelected
      ? addFilterTag(id, currentLobby)
      : removeFilterTag(id, currentLobby));
  };

  return (
    <div className="casino-filter__tag">
      <span
        className={classnames('bvs-button-filter-chip', { active: isFilterTagSelected })}
        onClick={filterTagsClickHandler}
      >
        {label || name}
      </span>
    </div>
  );
};

FilterTag.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FilterTag;
