// TODO: Helper to get the type and HOC instead of wrapper
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { underscored } from 'underscore.string';

import { selectComponent } from 'CasinoV2/selectors/casino_selector';
import * as Components from './casino_components';

const componentTypes = Object.keys(Components);

const ComponentWrapper = ({
  id, type, payload, path,
}) => {
  if (!componentTypes.includes(type)) return null;

  const tracking = {
    path,
    componentId: id,
    label: payload.label,
  };

  const CasinoComponent = Components[type];
  return (
    <div className={`casino-games__list-item ${underscored(type)}`}>
      <CasinoComponent
        id={id}
        {...payload}
        tracking={tracking}
        infiniteScroll={CasinoComponent === Components.CurrentCategoryGamesComponent}
        isCasinoComponent
        componentId={id}
      />
    </div>
  );
};

ComponentWrapper.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  payload: PropTypes.instanceOf(Object).isRequired,
  path: PropTypes.string.isRequired,
};

export { ComponentWrapper as CasinoComponentWrapper };
export default connect(selectComponent)(ComponentWrapper);
