import { GamesSlider } from 'casino-components';

const VideoGamesListComponent = (props) => (
  <GamesSlider
    {...props}
    isVideoTile
  />
);

export default VideoGamesListComponent;
