import PropTypes from 'prop-types';
import { RenderInFullscreen } from 'bv-components';
import { t } from 'bv-i18n';

import Filter from './filter';

const CasinoFilterModal = ({ onClose }) => (
  <RenderInFullscreen
    title={t('all_categories')}
    className="casino-categories-modal"
    onCloseClick={onClose}
  >
    <Filter closeModal={onClose} />
  </RenderInFullscreen>
);

CasinoFilterModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CasinoFilterModal;
