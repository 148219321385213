import {
  useEffect, useState, useRef, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { throttle, noop } from 'underscore';
import { isDesktop } from 'bv';
import Context from 'casino-context';

import { useLoggedIn } from 'bv-hooks';
import App from './app';
import ScrollingContext from './scrolling_context';

const AppContainer = ({
  fetchCatalog,
  resetCasino,
}) => {
  const [fixedNav, setFixedNav] = useState(false);
  const contentElRef = useRef(null);
  const { slug } = useContext(Context);

  const contextValue = useMemo(() => ({ contentElRef, fixedNav }), [contentElRef, fixedNav]);

  const loggedIn = useLoggedIn();

  // Listen for scroll and acts accordingly
  useEffect(() => {
    if (isDesktop()) return noop;

    const scrollAmount = 170;

    const calculateFixedNav = throttle(() => {
      setFixedNav(window.scrollY >= scrollAmount);
    }, 150);

    window.addEventListener('scroll', calculateFixedNav);

    return () => { window.removeEventListener('scroll', calculateFixedNav); };
  }, []);

  // Fetch catalog
  useEffect(() => {
    fetchCatalog();

    return resetCasino;
  }, [slug, loggedIn]);

  return (
    <ScrollingContext.Provider value={contextValue}>
      <App contentElRef={contentElRef} />
    </ScrollingContext.Provider>
  );
};

AppContainer.propTypes = {
  fetchCatalog: PropTypes.func.isRequired,
  resetCasino: PropTypes.func.isRequired,
};

export default AppContainer;
