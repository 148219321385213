import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';

import { GamesSlider } from 'casino-components';
import { fetchPersonalizedGames } from 'CasinoV2/ducks/casino_thunks';

export const PersonalizedGames = ({
  id, fetchGames, type, games, ...gamesListProps
}) => {
  useEffect(() => {
    fetchGames(id, type);
  }, []);

  if (!games || !games.length) return null;

  return <GamesSlider games={games} id={id} {...gamesListProps} />;
};

PersonalizedGames.propTypes = {
  fetchGames: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  games: PropTypes.instanceOf(Array).isRequired,
  id: PropTypes.number.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  fetchGames: compose(dispatch, fetchPersonalizedGames),
});

export default connect(null, mapDispatchToProps)(PersonalizedGames);
