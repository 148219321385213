import { t } from 'bv-i18n';
import PropTypes from 'prop-types';

const SuppliersSeeAllLess = ({ seeAll, onClick }) => {
  const showAllLessText = seeAll
    ? t('casino.all_categories_filter.see_all')
    : t('casino.all_categories_filter.see_less');

  return (
    <div className="casino-filter__tag-see-all">
      <span
        className="bvs-button-card"
        onClick={onClick}
      >
        {showAllLessText}
      </span>
    </div>
  );
};

SuppliersSeeAllLess.propTypes = {
  seeAll: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SuppliersSeeAllLess;
