import PropTypes from 'prop-types';
import Banners from 'bv-banners';

const BannerComponent = ({ location }) => (
  <div className="casino-components--banner-container">
    <Banners location={location} />
  </div>
);

BannerComponent.propTypes = {
  location: PropTypes.string.isRequired,
};

export default BannerComponent;
