import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchComponents } from 'CasinoV2/ducks/casino_thunks';
import {
  selectComponents,
  selectIsFetchingComponents,
} from 'CasinoV2/selectors/casino_selector';
import { GamesListSkeleton } from 'CasinoV2/helpers/skeletons';
import ComponentWrapper from '../category_page/component_wrapper';

const FavoritesPage = () => {
  const dispatch = useDispatch();

  const components = useSelector(selectComponents);
  const fetching = useSelector(selectIsFetchingComponents);

  useEffect(() => {
    dispatch(fetchComponents('favorites'));
  }, []);

  if (fetching) return <GamesListSkeleton />;

  return (
    <div className="casino-games__list">
      {components.map((id) => (
        <ComponentWrapper id={id} key={id} path="favorites" />
      ))}
    </div>
  );
};

export default FavoritesPage;
