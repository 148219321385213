import { useContext, useState } from 'react';
import classNames from 'classnames';
import { SearchInput } from 'bv-components';
import { useSelector } from 'react-redux';
import { selectShowSearch } from 'CasinoV2/selectors/casino_selector';
import { useFeature, useLoggedIn } from 'bv-hooks';
import ScrollingContext from '../scrolling_context';
import FavoritesButton from './favorites_button';
import AllCategoriesButton from './all_categories_button';

const SearchBar = () => {
  const { fixedNav } = useContext(ScrollingContext);
  const [favoritesEnabled] = useFeature('favourite_casino_games');
  const [allCategoriesEnabled] = useFeature('casino_games_collections');
  const showSearch = useSelector(selectShowSearch);
  const loggedIn = useLoggedIn();

  const showFavorites = (favoritesEnabled && loggedIn);

  // It has to be active if the search page is reloaded
  const [searchActive, setSearchActive] = useState(false);

  const wrapperClass = classNames('casino-search-nav', {
    'is-searching': searchActive,
    'is-scrolling': fixedNav,
  });

  if (!showFavorites && !showSearch) return null;

  return (
    <div className={wrapperClass}>
      {showSearch && <SearchInput setSearchActive={setSearchActive} />}
      <div className="casino-search-nav__options">
        {showFavorites && <FavoritesButton />}
        {allCategoriesEnabled && <AllCategoriesButton />}
      </div>
    </div>
  );
};

export default SearchBar;
